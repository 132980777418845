/* eslint-disable no-mixed-operators */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "@rjsf/bootstrap-4";
import { Button } from "react-bootstrap";
// import { graphql, useStaticQuery } from "gatsby";
import { FaAsterisk } from "react-icons/fa";
import _ from "lodash";
import {
  injectIntl,
  FormattedMessage,
  navigate,
  FormattedHTMLMessage
} from "gatsby-plugin-intl";
import LayoutField from "react-jsonschema-form-layout-2";
// const Recaptcha = require("react-recaptcha");
const fetch = require("node-fetch");

// let recaptchaToken = "";

const CustomTitleField = ({ title, required }) => {
  const legend = required ? title + <FaAsterisk /> : title;
  return <h2 className={"intake-form__title"}>{legend}</h2>;
};

const CustomDescriptionField = ({ id, description }) => {
  return (
    <p className={"intake-form__description"} id={id}>
      <span className="required-field required-field--description">
        <FaAsterisk />
      </span>{" "}
      {description}
    </p>
  );
};

// Purpose of this custom widget is to customize the label markup, for styling
// and accessibility.
const CustomTextWidget = props => {
  const { id, label, value, classNames, schema, required, placeholder } = props;
  console.log("props in Text", props);
  let defaultValue;
  if (!value) {
    defaultValue = "";
  } else {
    defaultValue = value;
  }

  return (
    <div className={classNames}>
      <label className="form-label intake-form__label" htmlFor={id}>
        <div>
          {label}
          <span className="required-field">
            {required ? <FaAsterisk className="align-top" /> : null}
          </span>
        </div>
      </label>
      <input
        type={schema.type}
        placeholder={placeholder}
        className="form-control"
        id={id}
        value={defaultValue}
        required={props.required}
        onChange={event => props.onChange(event.target.value)}
      />
    </div>
  );
};

// Purpose of this custom widget is to customize the label markup, for styling
// and accessibility.
const CustomEmailWidget = props => {
  const { id, label, value, classNames, required, schema } = props;

  let defaultValue;

  if (!value) {
    defaultValue = "";
  } else {
    defaultValue = value;
  }
  return (
    <div className={classNames}>
      <label className="form-label intake-form__label" htmlFor={id}>
        <div>
          {label}
          <span className="required-field">
            {required ? <FaAsterisk className="align-top" /> : null}
          </span>
        </div>
      </label>
      <input
        type="email"
        className="form-control"
        id={id}
        value={defaultValue}
        required={props.required}
        onChange={event => props.onChange(event.target.value)}
      />
      <p className="mt-2">
        <strong>{schema.help}</strong>
      </p>
    </div>
  );
};

const CustomDateWidget = props => {
  const { id, label, value, classNames, required } = props;

  let defaultValue;

  if (!value) {
    defaultValue = "";
  } else {
    defaultValue = value;
  }
  return (
    <div className={classNames}>
      <label className="form-label intake-form__label" htmlFor={id}>
        <div>
          {label}
          <span className="required-field">
            {required ? <FaAsterisk className="align-top" /> : null}
          </span>
        </div>
      </label>
      <input
        type="date"
        className="form-control"
        id={id}
        value={defaultValue}
        required={props.required}
        onChange={event => props.onChange(event.target.value)}
      />
    </div>
  );
};

const CustomSelectWidget = props => {
  const {
    id,
    label,
    required,
    schema,
    placeholder,
    value,
    options,
    onChange
  } = props;

  if (id === "root_state") {
    options.enumOptions = [{ label: "California", value: "California" }];
  }
  let defaultValue;

  if (!value) {
    defaultValue = "";
  } else {
    defaultValue = options.enumOptions[0].value;
  }

  return (
    <div disabled={schema.disabled} required={required} className="form-group">
      <label className="form-label intake-form__label" htmlFor={id}>
        <div>
          {label}
          <span className="required-field">
            {required ? <FaAsterisk className="align-top" /> : null}
          </span>
        </div>
      </label>
      <select
        id={id}
        className="custom-select"
        value={defaultValue}
        onChange={event => onChange(event.target.value)}
        name={schema.title}
      >
        <option>{placeholder}</option>
        {options &&
          options.enumOptions.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
      </select>
    </div>
  );
};

const CustomCheckboxesWidget = props => {
  function selectValue(value, selected, all) {
    const at = all.indexOf(value);
    const updated = selected.slice(0, at).concat(value, selected.slice(at));
    // As inserting values at predefined index positions doesn't work with empty
    // arrays, we need to reorder the updated selection to match the initial order
    return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
  }

  function deselectValue(value, selected) {
    return selected.filter(v => v !== value);
  }

  const {
    id,
    disabled,
    label,
    options,
    required,
    value,
    autofocus,
    readonly,
    onChange,
    schema
  } = props;

  const { enumOptions, enumDisabled, inline } = options;
  let checkboxesLabel = "";
  if (!label) {
    checkboxesLabel = schema.title;
  } else {
    checkboxesLabel = label;
  }
  return (
    <div className="checkboxes" id={id}>
      <label
        className="form-check-label form-label intake-form__label"
        htmlFor={id}
      >
        <div>
          <FormattedHTMLMessage
            id={checkboxesLabel}
            defaultMessage={checkboxesLabel}
          >
            {checkboxesLabel}
          </FormattedHTMLMessage>
          <span className="required-field">
            {required ? <FaAsterisk className="align-top" /> : null}
          </span>
          <span className="ml-2 text-primary">
            ( Select all that apply for the consumer )
          </span>
        </div>
      </label>

      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1;
        const itemDisabled =
          enumDisabled && enumDisabled.indexOf(option.value) != -1;
        const disabledCls =
          disabled || itemDisabled || readonly ? "disabled" : "";
        const checkbox = (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={event => {
                const all = enumOptions.map(({ value }) => value);
                if (event.target.checked) {
                  onChange(selectValue(option.value, value, all));
                } else {
                  onChange(deselectValue(option.value, value));
                }
              }}
            />
            <span className="form-check-label">{option.label}</span>
          </div>
        );
        return inline ? (
          <label key={index} className={`checkbox-inline ${disabledCls}`}>
            {checkbox}
          </label>
        ) : (
          <div key={index} className={`form-check checkbox ${disabledCls}`}>
            <label className="form-check-label">{checkbox}</label>
          </div>
        );
      })}
    </div>
  );
};

function CustomRadioWidget(props) {
  const {
    options,
    label,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    id
  } = props;

  var name = Math.random().toString();
  var enumOptions = options.enumOptions,
    enumDisabled = options.enumDisabled,
    inline = options.inline;
  // checked={checked} has been moved above name={name}, As mentioned in #349;
  // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.

  let radioLabel = "";
  if (!label) {
    radioLabel = props.schema.title;
  } else {
    radioLabel = label;
  }

  return (
    <div className="form-group" id={id}>
      <label className="form-label intake-form__label" htmlFor={id}>
        {radioLabel}
        <span className="required-field">
          {required ? <FaAsterisk /> : null}
        </span>
      </label>
      {enumOptions.map((option, index) => {
        var checked = option.value === value;
        var itemDisabled =
          enumDisabled && enumDisabled.indexOf(option.value) != -1;
        var disabledCls =
          disabled || itemDisabled || readonly ? "disabled" : "";
        const radio = (
          <>
            <input
              type="radio"
              checked={checked}
              name={name}
              className="form-check-input"
              required={required}
              value={option.value}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={() => onChange(option.value)}
              onBlur={
                onBlur &&
                function(event) {
                  return onBlur(id, event.target.value);
                }
              }
              onFocus={
                onFocus &&
                function(event) {
                  return onFocus(id, event.target.value);
                }
              }
            />
            <span className="form-radio-text">{option.label}</span>
          </>
        );
        return inline ? (
          <label key={index} className={`radio-inline ${disabledCls}`}>
            {radio}
          </label>
        ) : (
          <div key={index} className={`form-check radio ${disabledCls}`}>
            <label className="form-check-label">{radio}</label>
          </div>
        );
      })}
    </div>
  );
}

const customFormats = {
  "number-only": /^[0-9]*$/,
  "email-validation": /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

const widgets = {
  customTextWidget: CustomTextWidget,
  customDateWidget: CustomDateWidget,
  customEmailWidget: CustomEmailWidget,
  customSelectWidget: CustomSelectWidget,
  customCheckboxesWidget: CustomCheckboxesWidget,
  customRadioWidget: CustomRadioWidget
};

const fields = {
  TitleField: CustomTitleField,
  DescriptionField: CustomDescriptionField,
  layout: LayoutField
};

const getDefaultTitle = name => name && name.replace(/[_-]/g, " ");
const getTypeScope = typeName => `types.${typeName}`;
const getPropScope = (scope, propName) => `${scope}.fields.${propName}`;

// From https://github.com/rjsf-team/react-jsonschema-form/issues/739#issuecomment-443617319
// Recursively localises given json schema object. Original schema is not modified.
//   schema is json schema object
//   scope is path to root of form/type localised text in i18n object. eg 'forms.intakeForm'
//   i18n is object containing localised text for schema
//   name is default name for root property, leave undefined for root
function intlSchema(schema, scope, i18n, name) {
  const newSchema = {
    ...schema,
    ..._.pickBy({
      title:
        i18n[scope] ||
        i18n[`${scope}.title`] ||
        schema.title ||
        (!schema.$ref && getDefaultTitle(name)),
      description: i18n[`${scope}.description`] || schema.description,
      help: i18n[`${scope}.help`] || schema.help
    })
  };

  if (schema.definitions) {
    newSchema.definitions = _.mapValues(
      schema.definitions,
      (typeSchema, typeName) =>
        intlSchema(typeSchema, getTypeScope(typeName), i18n, typeName)
    );
  }

  if (schema.type === "object") {
    newSchema.properties = _.mapValues(
      schema.properties,
      (propSchema, propName) =>
        intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
    );
  }

  if (
    schema.enum &&
    (!schema.enumNames || _.isEqual(schema.enum, schema.enumNames))
  ) {
    newSchema.enumNames = schema.enum.map(option =>
      _.defaultTo(i18n[`${scope}.options.${option}`], option)
    );
  }

  if (schema.items && schema.type === "array") {
    if (schema.items.enum && !schema.items.enumNames) {
      newSchema.items = {
        ...newSchema.items,
        enumNames: schema.items.enum.map(option =>
          _.defaultTo(i18n[`${scope}.options.${option}`], option)
        )
      };
    } else if (schema.items.properties && schema.items.type === "object") {
      newSchema.items = {
        ...newSchema.items,
        properties: _.mapValues(
          schema.items.properties,
          (propSchema, propName) =>
            intlSchema(
              propSchema,
              getPropScope(scope, propName),
              i18n,
              propName
            )
        )
      };
    }
  }
  return newSchema;
}

// Localises given json uiSchema object. Original uiSchema is not modified.
//   uiSchema is json schema object
//   scope is path to root of form/type localised text in i18n object. eg 'forms.intakeForm'
//   i18n is object containing localised text for schema
//   name is default name for root property, leave undefined for root
function intlUiSchema(uiSchema, scope, i18n) {
  let newUiSchema = {
    ...uiSchema
  };

  for (let item in uiSchema) {
    if (uiSchema[item]["ui:placeholder"]) {
      newUiSchema[item]["ui:placeholder"] =
        i18n[`${scope}.fields.${item}.placeholder`] ||
        uiSchema[item]["ui:placeholder"];
    }
  }
  return newUiSchema;
}

const IntakeForm = props => {
  const [showButtonError, setShowButtonError] = useState(false);
  const [formFieldsObj, setformFieldsObj] = useState(props.formData);
  const [reqFieldsFilled, setReqFieldsFilled] = useState(props.disableSubmit);
  const { enableLoader, relationshipValue } = props;
  let schema;
  let uiSchema;
  let formFields;
  let intl = props.intl;

  const submissionEndpoint = `https://aflac-dev.wirewheel.io/api/public/v1/self-service`;

  function getFieldDataForSingleRequestType(requestType) {
    let formRequests = props.configValues.requests;

    let requestTypeData = formRequests.filter(obj => {
      return obj.requestType === requestType;
    });
    return requestTypeData[0].fields;
  }

  function getFieldDataForRequestTypes(requestTypes) {
    let retArray = [];
    requestTypes.forEach(function(requestType) {
      let fieldData = getFieldDataForSingleRequestType(requestType);
      let fieldDataAddKeys = fieldData.map(function(el) {
        var o = Object.assign({}, el);
        o.requestType = requestType;
        return o;
      });
      retArray.push(...fieldDataAddKeys);
    });
    return retArray;
  }

  // TODO need to determine what we will do if request types don't all have
  // same set of fields.
  let fieldData = props.formFields;
  console.log("data", props.formFields);

  if (fieldData) {
    let formFieldsArr = fieldData.map(item => {
      const fieldInfo = {};
      let fieldType = "";
      let fieldName = item.label.slice(0);

      // In schema these must match these values,
      // in uiSchema they can be used to choose appropriate widgets.
      const stringFieldFormatToType = {
        text: "string",
        email: "string",
        select: "string",
        date: "string",
        checkbox: "array",
        radio: "string",
        number: "string"
      };

      if (item.rules) {
        if (item.rules.indexOf("number-only") >= 0) {
          fieldType = "string";
          fieldInfo.format = "number-only";
        }
        if (item.rules.indexOf("char-min") >= 0) {
          fieldInfo.minLength = parseInt(item.ruleOptions.min);
        }
        if (item.rules.indexOf("char-limit") >= 0) {
          fieldInfo.maxLength = parseInt(item.ruleOptions.limit);
        }
      }

      if (item.type in stringFieldFormatToType) {
        fieldType = stringFieldFormatToType[item.type];
        // Allowed formats for string fields.
        if (fieldType in ["uri", "data-url", "date", "date-time"]) {
          fieldInfo.format = item.type;
        }
      } else {
        fieldType = item.type;
      }

      if (item.type === "email") {
        fieldInfo.format = "email-validation";
      }

      fieldInfo.apiIdentifier = item.apiIdentifier;
      fieldInfo.label = _.kebabCase([fieldName]);
      fieldInfo.title = item.label;
      fieldInfo.default = false;
      fieldInfo.required = item.required;
      fieldInfo._id = item._id;
      fieldInfo.key = item.key;
      fieldInfo.type = fieldType;
      if (item.type === "select" && item.items) {
        fieldInfo.enum = item.items;
        fieldInfo.enumOptions = item.items;
      }
      if (item.type === "radio" && item.items) {
        fieldInfo.enum = item.items;
        fieldInfo.enumOptions = item.items;
      }
      if (item.type === "checkbox" && item.items) {
        fieldInfo.items = {
          type: "string",
          enum: item.items
        };
        // fieldInfo.enumOptions = item.items;
        fieldInfo.uniqueItems = true;
      }
      return fieldInfo;
    });

    // Initialized above, also used in form submission.
    formFields = formFieldsArr.reduce((acc, item) => {
      acc[item.apiIdentifier] = {};
      acc[item.apiIdentifier].title = item.title;
      acc[item.apiIdentifier].type = item.type;
      if (item.minLength) {
        acc[item.apiIdentifier].minLength = item.minLength;
      }
      if (item.maxLength) {
        acc[item.apiIdentifier].maxLength = item.maxLength;
      }
      if (item.pattern) {
        acc[item.apiIdentifier].pattern = item.pattern;
      }
      if (item.format) {
        acc[item.apiIdentifier].format = item.format;
      }
      if (item.default) {
        acc[item.apiIdentifier].default = item.default;
      }
      acc[item.apiIdentifier]._id = item._id;
      acc[item.apiIdentifier].key = item.key;
      acc[item.apiIdentifier].apiIdentifier = item.apiIdentifier;
      if (item.enum && item.enumOptions) {
        acc[item.apiIdentifier].enum = item.enum;
        acc[item.apiIdentifier].enumOptions = item.enumOptions;
      }
      if (item.items) {
        acc[item.apiIdentifier].items = item.items;
      }
      if (item.uniqueItems) {
        acc[item.apiIdentifier].uniqueItems = item.uniqueItems;
      }

      return acc;
    }, {});

    const defaultRequiredArray = [
      "primaryInsuredFirstName",
      "primaryInsuredLastName",
      "email",
      "address1",
      "city",
      "state",
      "postalZipCode",
      "country",
      "primaryDOB"
    ];

    const schemaData = {
      title:
        props.relationshipValue ===
        "Customer (Policyholder, Certificate Holder, Spouse or Dependent)"
          ? "Please enter the required information for the Primary Insured."
          : "",
      description: "Required fields",
      help: "",
      type: "object",
      properties: formFields,
      required:
        relationshipValue ===
          "Customer (Policyholder, Certificate Holder, Spouse or Dependent)" &&
        props.hasAccDelCorrRequestType &&
        !props.hasCorrectionRequestType
          ? [
              ...defaultRequiredArray,
              "areYouAPolicyOrCertificateHolder",
              "areYouThePrimaryPersonWhoAppliedForCoverageOrTheSpouseDependent"
            ]
          : (relationshipValue ===
              "Customer (Policyholder, Certificate Holder, Spouse or Dependent)" &&
              props.hasCorrectionRequestType) ||
            props.hasCorrectionRequestType
          ? [
              ...defaultRequiredArray,
              "pleaseSpecifyWhatNeedsToBeUpdatedCorrected"
            ]
          : defaultRequiredArray,
      relationshipValue: relationshipValue
    };

    // TODO this should be build off graphql data like schema is.
    let uiSchemaData = {
      "ui:field": "layout",
      "ui:order": [
        "email",
        "areYouAPolicyOrCertificateHolder",
        "areYouThePrimaryPersonWhoAppliedForCoverageOrTheSpouseDependent",
        "primaryInsuredFirstName",
        "primaryInsuredLastName",
        "spouseDependentFirstName",
        "spouseDependentLastName",
        "address1",
        "address2",
        "city",
        "state",
        "postalZipCode",
        "country",
        "primaryDOB",
        "pleaseSpecifyWhatNeedsToBeUpdatedCorrected",
        "eNumber",
        "last4DigitsOfSSN",
        "writingNumber",
        "last4DigitsOfSSNTIN",
        "vNumber",
        "*"
      ],
      email: {
        "ui:widget": "customEmailWidget"
      },
      areYouAPolicyOrCertificateHolder: {
        "ui:widget": "customCheckboxesWidget"
      },
      areYouThePrimaryPersonWhoAppliedForCoverageOrTheSpouseDependent: {
        "ui:widget": "customRadioWidget"
      },
      primaryInsuredFirstName: {
        "ui:widget": "customTextWidget"
      },
      primaryInsuredLastName: {
        "ui:widget": "customTextWidget"
      },
      spouseDependentFirstName: {
        "ui:widget": "customTextWidget"
      },
      spouseDependentLastName: {
        "ui:widget": "customTextWidget"
      },
      address1: {
        "ui:widget": "customTextWidget"
      },
      address2: {
        "ui:widget": "customTextWidget"
      },
      city: {
        "ui:widget": "customTextWidget"
      },
      state: {
        "ui:widget": "customSelectWidget",
        "ui:placeholder": "Select State"
      },
      postalZipCode: {
        "ui:widget": "customTextWidget"
      },
      country: {
        "ui:widget": "customSelectWidget",
        "ui:placeholder": "Select Country"
      },
      primaryDOB: {
        "ui:widget": "customDateWidget"
      },
      pleaseSpecifyWhatNeedsToBeUpdatedCorrected: {
        "ui:widget": "customTextWidget",
        "ui:placeholder":
          "Please do not provide any sensitive personal information."
      },
      eNumber: {
        "ui:widget": "customTextWidget"
      },
      last4DigitsOfSSN: {
        "ui:widget": "customTextWidget"
      },
      writingNumber: {
        "ui:widget": "customTextWidget"
      },
      last4DigitsOfSSNTIN: {
        "ui:widget": "customTextWidget"
      },
      vNumber: {
        "ui:widget": "customTextWidget"
      },

      "ui:layout": [
        {
          email: { md: 8 }
        },
        {
          pleaseSpecifyWhatNeedsToBeUpdatedCorrected: { md: 12 }
        },
        {
          areYouAPolicyOrCertificateHolder: { md: 12 }
        },
        {
          areYouThePrimaryPersonWhoAppliedForCoverageOrTheSpouseDependent: {
            md: 12
          }
        },
        {
          primaryInsuredFirstName: { md: 6 },
          primaryInsuredLastName: { md: 6 }
        },
        {
          spouseDependentFirstName: { md: 6 },
          spouseDependentLastName: { md: 6 }
        },
        {
          address1: { md: 6 },
          address2: { md: 6 }
        },
        {
          city: { md: 6 },
          state: { md: 6 }
        },
        {
          postalZipCode: { md: 6 },
          country: { md: 6 }
        },
        props.relationshipValue === "Employee"
          ? {
              eNumber: { md: 6 },
              last4DigitsOfSSN: { md: 6 }
            }
          : props.relationshipValue === "Sales Agent or Broker"
          ? {
              writingNumber: { md: 6 },
              last4DigitsOfSSNTIN: { md: 6 }
            }
          : {},
        {
          primaryDOB: { md: 6 },
          vNumber: { md: 6 }
        }
      ]
    };

    schema = schemaData;
    uiSchema = uiSchemaData;
  }

  const formatSubmissionData = formData => {
    const preferredLanguage =
      props.selectedlang === "es" ? "Spanish" : "English";

    const newData = {
      ...formData,
      preferredLanguage: preferredLanguage,
      areYouACaliforniaResident: "Yes",
      whatIsYourCurrentOrFormerRelationshipToAflac: props.relationshipValue
    };

    let requestTypes = props.requestTypes;

    function filterByChecked(item) {
      if (requestTypes.length > 1) {
        if (item.isChecked) {
          return item["value"];
        }
      } else {
        if (!item.isChecked) {
          return item["value"];
        }
      }
    }

    let arrayByIsChecked = requestTypes.filter(filterByChecked);
    let providedRequests = arrayByIsChecked.map(item => {
      return item.value;
    });
    let formFields = getFieldDataForRequestTypes(providedRequests);
    let keys = Object.keys(newData);

    for (let fieldIndex in formFields) {
      keys.forEach(function(key) {
        if (key === formFields[fieldIndex].apiIdentifier) {
          formFields[fieldIndex].value = newData[key];
        }
      });
    }

    const pageDataId = props.formId;
    const submission = {
      data: {
        providedFields: Object.values(formFields),
        action: props.requestType,
        primaryEmail: newData.email,
        providedRequests: providedRequests.map(item => {
          return item;
        }),
        locale: props.selectedlang
        // recaptchaToken: recaptchaToken
      },
      meta: {
        id: props.requestId,
        pageDataId: pageDataId,
        type: "dsar"
      }
    };

    return submission;
  };

  const onSubmit = ({ formData }) => {
    event.preventDefault();
    enableLoader(true);
    // const newFormData = {
    //   ...formData,
    //   root_preferredLanguage: myRef.current.root_preferredLanguage.value
    // };
    const submission = formatSubmissionData(formData);
    setShowButtonError(false);
    fetch(submissionEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(submission)
    })
      .then(response => response.json())
      .then(data => {
        navigate(
          "/success",
          {
            state: {
              data,
              formType: props.formType,
              requestTypes: props.requestTypes
            }
          },
          () => {
            enableLoader(false);
          }
        );
      })
      .catch(error => console.log("error is", error)); // eslint-disable-line no-console
  };

  function validate(formData, errors) {
    if (formData.firstName && formData.firstName.length < 2) {
      errors.firstName.addError("Length must be greater than 1");
    }

    if (
      (formData.postalZipCode && formData.postalZipCode.length > 5) ||
      formData.postalZipCode.length < 5
    ) {
      errors.postalZipCode.addError("Invalid Zipcode, Five digits only.");
    }

    const checkPostalCode = /[a-zA-Z]/.test(formData.postalZipCode);

    if (formData.postalZipCode && checkPostalCode) {
      errors.postalZipCode.addError("Invalid Zipcode, Numerical values only.");
    }

    return errors;
  }

  function transformErrors(errors) {
    return errors.map(error => {
      if (error.name === "format" && error.params.format === "number-only") {
        error.message = intl.formatMessage({
          id: "form.validation.numeric",
          defaultMessage: "Numerical values only."
        });
      }

      if (
        error.name === "format" &&
        error.params.format === "email-validation"
      ) {
        error.message = intl.formatMessage({
          id: "form.validation.email",
          defaultMessage: "Invalid email address."
        });
      }

      if (
        error.name === "format" &&
        error.params.format === "phone-validation"
      ) {
        error.message = intl.formatMessage({
          id: "form.validation.phone",
          defaultMessage: "Invalid phone number."
        });
      }

      if (error.name === "required") {
        let fieldName = _.startCase(error.params.missingProperty);
        error.message = intl.formatMessage(
          {
            id: "form.validation.required",
            fieldName: fieldName,
            defaultMessage: "{fieldName} is required."
          },
          { fieldName: fieldName }
        );
      }

      if (error.name === "minLength") {
        error.message = intl.formatMessage(
          {
            id: "form.validation.min-length",
            defaultMessage: "Min. character limit not reached."
          },
          { limit: error.params.limit }
        );
      }

      if (error.name === "maxLength") {
        error.message = intl.formatMessage(
          {
            id: "form.validation.max-length",
            defaultMessage: "Max. character limit reached."
          },
          { limit: error.params.limit }
        );
      }

      return error;
    });
  }

  useEffect(() => {
    if (formFieldsObj && Object.keys(formFieldsObj).length > 0) {
      let valuesFilled = schema.required.every(item => {
        if (Array.isArray(formFieldsObj[item])) {
          if (formFieldsObj[item].length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          if (formFieldsObj[item]) {
            return true;
          } else {
            return false;
          }
        }
      });

      if (
        valuesFilled &&
        schema.required.every(val => Object.keys(formFieldsObj).includes(val))
      ) {
        setReqFieldsFilled(false);
        props.onFormChange(false);
      } else {
        setReqFieldsFilled(true);
        props.onFormChange(true);
      }
    }
  }, [formFieldsObj]);

  return (
    <Form
      id={`intake-form-${props.formType}-${props.requestType}-${props.intl.locale}`}
      schema={intlSchema(schema, "form", props.intl.messages)}
      uiSchema={intlUiSchema(uiSchema, "form", props.intl.messages)}
      className={"intake-form"}
      onSubmit={onSubmit}
      onError={() => setShowButtonError(true)}
      fields={fields}
      widgets={widgets}
      customFormats={customFormats}
      noHtml5Validate
      validate={validate}
      showErrorList={false}
      transformErrors={transformErrors}
      onChange={e => {
        props.setFormData({
          formData: e.formData
        });
        setformFieldsObj(e.formData);
      }}
      formData={props.formData}
    >
      {/* <div className="d-flex justify-content-center my-3">
        <Recaptcha
          sitekey={recaptchaSiteKey}
          elementID={`${props.intl.locale}`}
          verifyCallback={verifyCallback}
          hl={props.intl.locale}
          render="explicit"
          onloadCallback={loadCallback}
          expiredCallback={expiredCallback}
        />
      </div> */}

      <div>
        <Button
          type="submit"
          variant="dark"
          className="btn btn-primary mt-4"
          style={{ backgroundColor: "#00A7E1", textDecoration: "none" }}
          disabled={reqFieldsFilled}
        >
          <FormattedMessage
            defaultMessage={"Submit Request"}
            id="form.submit"
            values={{
              item: msg => ({ msg })
            }}
          />
        </Button>
        {showButtonError && (
          <div className="color-container">
            <p className="color-container--text-red">
              <FormattedMessage
                defaultMessage={
                  "Please fill out all the required fields and submit the form to continue."
                }
                id="form.validation.missing-fields"
                values={{
                  item: msg => ({ msg })
                }}
              />
            </p>
          </div>
        )}
      </div>
    </Form>
  );
};

CustomTitleField.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool
};

CustomDescriptionField.propTypes = {
  id: PropTypes.any,
  description: PropTypes.any
};

CustomTextWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any
};

CustomEmailWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
  emailCaption: PropTypes.any
};

CustomDateWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any
};

CustomSelectWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  options: PropTypes.any
};

CustomCheckboxesWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  options: PropTypes.any,
  disabled: PropTypes.any,
  autofocus: PropTypes.any,
  readonly: PropTypes.any
};

CustomRadioWidget.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  classNames: PropTypes.any,
  schema: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
  onBlur: PropTypes.any,
  onFocus: PropTypes.any,
  placeholder: PropTypes.any,
  options: PropTypes.any,
  disabled: PropTypes.any,
  autofocus: PropTypes.any,
  readonly: PropTypes.any
};

IntakeForm.propTypes = {
  intl: PropTypes.any,
  requestTypes: PropTypes.any,
  onFormChange: PropTypes.any,
  setFormData: PropTypes.any,
  disableSubmit: PropTypes.any,
  formData: PropTypes.any,
  activeRequestTypes: PropTypes.array,
  formType: PropTypes.string,
  settingsId: PropTypes.string,
  formId: PropTypes.string,
  requestId: PropTypes.string,
  formFields: PropTypes.arrayOf(PropTypes.object),
  requestType: PropTypes.array,
  enableLoader: PropTypes.func,
  handleFormChange: PropTypes.any,
  configValues: PropTypes.any,
  settings: PropTypes.string,
  selectedlang: PropTypes.string,
  relationshipValue: PropTypes.string,
  hasAccDelCorrRequestType: PropTypes.bool,
  hasCorrectionRequestType: PropTypes.bool
};

export default injectIntl(IntakeForm);
